import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Countdown from 'react-countdown-now';

class CountDown extends Component{
    render(){
        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              // Render a completed state
              return <span>You Work is Well</span>
            } else {
              // Render a countdown
              return <span className="time-counterwrapper">
                    <span className="time-inner">{days}<span className="text">Days</span></span>
                    <span className="time-inner">{hours}<span className="text">Hours</span></span>
                    <span className="time-inner">{minutes}<span className="text">Minutes</span></span>
                    <span className="time-inner">{seconds}<span className="text">Seconds</span></span>
                </span>;
            }
        };

        return(
            <Fragment>
                <Helmet>
                    <title>Countdown || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Countdown'} />
                {/* End Breadcrumb Area */}

                {/* Start Countdown Area */}
                <div className="countdown-area ptb--120 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--60">
                                    <h2 className="font--40">Great projects are being under development now</h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="countdown-style--1">
                                    <Countdown date={new Date('2025')} renderer={renderer} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End Countdown Area */}




            </Fragment>
        )
    }
}
export default CountDown;
