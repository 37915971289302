import React, { Component } from "react";

import Slider from "react-slick"
import {tabSlide1, tabSlide2} from "../../../home/script";

class TabsThree extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.setState({
          nav1: this.tabSlide1,
          nav2: this.tabSlide2
        });
    }
    render(){
        return(
            <div className="tabs-three-area tab-style--2 ptb--120 text-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="tab-content-wrapper">
                                <Slider {...tabSlide1} asNavFor={this.state.nav2} ref={slider => (this.tabSlide1 = slider)} className="testimonial-image-slider text-center">
                                    <div className="tab2-content">
                                        <div className="title">
                                            <i className="fa fa-align-left"/>
                                            <h4>Page Change Effects</h4>
                                        </div>
                                        <p>The transition between pages is made to provide convenience for users while navigating.</p>
                                    </div>
                                    
                                    <div className="tab2-content mt--40">
                                        <div className="title">
                                            <i className="fa fa-rocket"/>
                                            <h4>New Marketing Plan</h4>
                                        </div>
                                        <p>We are capable of sketching out competitive marketing plans that's efficiency proven.</p>
                                    </div>
                                    
                                    <div className="tab2-content mt--40">
                                        <div className="title">
                                            <i className="fa fa-chart-pie"/>
                                            <h4>SEO Analytic Tools</h4>
                                        </div>
                                        <p>Promote your sales and increase the ROI with the help from built-in SEO analytics tools.</p>
                                    </div>
                                    
                                </Slider>
                            </div>
                        </div>
                        <div className="col-lg-8 mt_md--40 mt_sm--40">
                            <div className="tab2-thumbnail-wrapper slick-dots-style">
                                <Slider {...tabSlide2} asNavFor={this.state.nav1} ref={slider => (this.tabSlide2 = slider)} className="testimonial-text-slider text-center">
                                    
                                    <div className="thumbnail">
                                        <img src={require('../../../assets/images/aeroland/tab-content-image-04.jpg').default} alt="app landing"/>
                                    </div>
                                    
                                    <div className="thumbnail">
                                        <img src={require('../../../assets/images/aeroland/tab-content-image-05.jpg').default} alt="app landing"/>
                                    </div>
                                    
                                    <div className="thumbnail">
                                        <img src={require('../../../assets/images/aeroland/tab-content-image-06.jpg').default} alt="app landing"/>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default TabsThree;