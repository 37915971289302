import React, { Component } from "react";
import ListOne from "../../../elements/component/list/ListOne";
import Slider from "react-slick";
import { SliedScreenshot } from "../../../home/script";


class ScreenShot extends Component{
    render(){
        return(
            <div className="app-screenshot-wrapper">
                <div className="screenshot-top pb--110">
                    <div className="row">
                        <div className="col-lg-12 col-xl-6">
                            <div className="row">
                                <div className="col-lg-6 col-xl-4">
                                    <div className="app-screens-inner">
                                        <h2 className="text-white font--80">+60</h2>
                                        <span className="screen">Screens</span>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-8">
                                    <div className="section-title">
                                        <h2 className="text-white">Download <br /> to your phone</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-2 offset-xl-0  col-xl-6 mt_lg--20 mt_md--30 mt_sm--30">
                            <div className="applist">
                                <ListOne />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Screenshot */}
                <div className="screenshot-slide-wrapper slick-default-dots button--white mb--70">
                    <Slider {...SliedScreenshot}>
                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-01.jpg').default} alt="App Landing"/>
                            </div>
                        </div>

                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-02.jpg').default} alt="App Landing"/>
                            </div>
                        </div>

                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-03.jpg').default} alt="App Landing"/>
                            </div>
                        </div>

                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-04.jpg').default} alt="App Landing"/>
                            </div>
                        </div>

                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-05.jpg').default} alt="App Landing"/>
                            </div>
                        </div>

                        <div className="screenshot">
                            <div className="thumb">
                                <img src={require('../../../assets/images/screenshot/screenshot-01.jpg').default} alt="App Landing"/>
                            </div>
                        </div>
                    </Slider>
                </div>

                {/* End Screenshot */}
            </div>
        )
    }
}
export default ScreenShot;