import React , { Component } from "react";
import {Helmet} from 'react-helmet';
import { FiDownload } from "react-icons/fi";
import HeaderOne from '../components/header/HeaderOne';
import PricingBoxFour from '../elements/component/pricingBox/PricingBoxFour';
import TestimonialOne from '../elements/component/testimonial/TestimonialOne';
import AllFeatured from '../components/layouts/applanding/AllFeatured';
import ScreenShot from '../components/layouts/applanding/ScreenShot';
import FooterThree from '../components/footer/FooterThree';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";


class AppLanding extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>App Landing | Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--applanding">
                    <div className="slide-wrapper fullscreen bg_image bg_image--16 d-flex align-items-center">
                        <div className="slide-app-landing">
                            <h1 className="title">Exclusive Mobile <br />App Today!</h1>
                            <h6>Apps designed by our experts can work perfectly on any devices with high responsiveness and performance.</h6>
                            <button className="ar-button secondary style-flat with-left" type="submit"><span className="button-text"><FiDownload /> Get Aeroland</span></button>
                        </div>
                    </div>
                </div>
                {/* End Slider Area */}

                {/* Start Feature Area */}
                <div className="app-feature-area ptb--120 bg_color--6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-1">
                                <div className="section-title">
                                    <h2 className="text-white">A Collection of Prominent Features</h2>
                                </div>
                            </div>
                            {/* Start Feature Area */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 offset-lg-2 mt_md--30 mt_sm--30">
                                <div className="app-feature">
                                    <div className="icon">
                                        <i className="fa fa-bell"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Web Development</h4>
                                        <p>Our technical experts have a flair for developing clean-coded & seamless websites based on customers’ needs, global guidelines and trends.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Area */}

                            {/* Start Feature Area */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 offset-lg-1 mt--60 mt_lg--30 mt_md--30 mt_sm--30">
                                <div className="app-feature">
                                    <div className="icon">
                                        <i className="fa fa-rocket"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Social Media</h4>
                                        <p>Our presence on a wide range of social platforms leads to better marketing results, larger customer base & bigger cooperating opportunities.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Area */}

                            {/* Start Feature Area */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 offset-lg-2 mt--60 mt_lg--30 mt_md--30 mt_sm--30">
                                <div className="app-feature">
                                    <div className="icon">
                                        <i className="fa fa-download"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">SEO Marketing</h4>
                                        <p>From meticulous research on search engine optimization, we have created creative marketing strategies to increase the SEO efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Area */}

                            {/* Start Feature Area */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 offset-lg-1 mt--60 mt_lg--30 mt_md--30 mt_sm--30">
                                <div className="app-feature">
                                    <div className="icon">
                                        <i className="fa fa-bolt"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Keyword Search</h4>
                                        <p>Studies showed that the choice of appropriate keywords for your sites' content, portfolios as well as posts help increase the site ranking.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Area */}

                            {/* Start Feature Area */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 offset-lg-2 mt--60 mt_lg--30 mt_md--30 mt_sm--30">
                                <div className="app-feature">
                                    <div className="icon">
                                        <i className="fa fa-mobile-alt"></i>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Email Optimization</h4>
                                        <p>Keep your audiences in touch with your company’s latest news, upcoming events, and new product launch via newsletters delivered to inbox.</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Feature Area */}

                        </div>
                    </div>
                </div>
                {/* End Feature Area */}

                {/* Start All Feature Area */}
                <div className="all-feature-area pb--120 bg_color--6">
                    <AllFeatured />
                </div>
                {/* End All Feature Area */}

                {/* Start Screenshot Area */}
                <div className="all-screenshot-area ptb--120 bg_color--7">
                    <ScreenShot />
                </div>
                {/* End Screenshot Area */}

                {/* Start Pricing Box */}
                <div className="pricing-box-area ptb--120 bg_color--6">
                    <div className="pricong-box-wrapper pl--130 pr--130">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="section-title mb--20">
                                    <h2 className="fontWeight400 text-white mb--35">Affordable pricing plans!</h2>
                                    <ul className="list-style--1 text-white">
                                        <li><i className="fa fa-check"></i> Fast, reliable and secure</li>
                                        <li><i className="fa fa-check"></i> Extendable support period</li>
                                        <li><i className="fa fa-check"></i> Regular design updates</li>
                                    </ul>
                                    <h6 className="text-white mt--40 mb--0 font--15">What’s next in AeroLand? <a className="color-4 link-hover" href="/">Learn more</a> </h6>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <PricingBoxFour />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Pricing Box */}

                {/* Start Testimonial Area */}
                <div className="testimonial-area bg_color--8">
                    <div className="container thine-line-gray applanding-clint-inner ptb--90">
                        <TestimonialOne />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="brand-area app-landing-partner pt--90 pb--300 bg_color--8">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="content">
                                    <div className="inner">
                                        <h3 className="text-white mb--20">We’ve established partnership with partners all over the globe.</h3>
                                        <p className="color-5">We’ve made the negotiation with impact-first investors dedicated to expanding opportunities for small-sized businesses to make loans and early stage investments.</p>
                                        <button className="ar-button style-flat mt--50 mt_sm--20 mt_md--20" type="submit">
                                            <span className="button-text">View all clients</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-1 mt_sm--40 mt_md--40">
                                <div className="partner-style--1">
                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-01.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-01.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-02.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-02.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-03.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-03.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-04.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-04.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-05.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-05.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>

                                    <div className="brand">
                                        <a href="/">
                                            <div className="thumb2">
                                                <img src={require('../assets/images/brand/client2-06.png').default} alt="Client Images" />
                                            </div>
                                            <div className="thumb">
                                                <img src={require('../assets/images/brand/client-06.png').default} alt="Client Images" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterThree />
            </React.Fragment>
        )
    }
}
export default AppLanding;