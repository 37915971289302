import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import CalltoActionOne from "./component/callto-action/CalltoActionOne";
import CalltoActionTwo from "./component/callto-action/CalltoActionTwo";
import CalltoActionThree from "./component/callto-action/CalltoActionThree";
import CalltoActionFour from "./component/callto-action/CalltoActionFour";



class CallToAction extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Call to action || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Call to action'} />
                {/* End Breadcrumb Area */}

                {/* Start Call TO Action */}
                <div className="call-to-action-wrapper ptb--120">
                    <CalltoActionOne />
                </div>
                {/* End Call TO Action */}

                {/* Start Call TO Action */}
                <div className="call-to-action-wrapper call-to-action-bg ptb--80 bg-color" data-bg-color="#f6fafe">
                    <CalltoActionTwo />
                </div>
                {/* End Call TO Action */}

                {/* Start Call TO Action */}
                <div className="call-to-action-wrapper call-to-action-bg mt--100">
                    <CalltoActionThree />
                </div>
                {/* End Call TO Action */}

                {/* Start Call TO Action */}
                <div className="call-to-action-wrapper call-to-action-four-bg theme-gradient--2 mt--100">
                    <CalltoActionFour />
                </div>
                {/* End Call TO Action */}

                

            </div>
        )
    }
}
export default CallToAction;
