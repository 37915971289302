import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import SectionTitle01 from "../../sectiontitle/SectionTitle01";
import Service from "../../service/Service";



class BusinessService extends Component{
    render(){
        return(
            <div className="business-service-area ptb--120">
                <Container>
                    <Row>
                        <Col lg="12">
                            <SectionTitle01 titleAlign="text-center" />
                        </Col>
                    </Row>
                    <Service />
                    <Row className="mt--70">
                        <Col xs="12">
                            <div className="button-group text-center">
                                <button className="ar-button style-flat" type="submit"><span className="button-text">Get started</span></button>
                                <button className="ar-button style-white" type="submit"><span className="button-text">Contact us</span></button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


export default BusinessService;

