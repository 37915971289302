import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet';
import Breadcrumb from "../components/common/Breadcrumb";
import BlogClassicOne from "./components/BlogClassicOne";


class BlogTwoColumn extends Component{
    render(){
        return(
            <Fragment>
                <Helmet>
                    <title>Blog Two Column || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Blog Two Column'} />
                {/* End Breadcrumb Area */}

                {/* Start Blog Classic Area */}
                <div className="blog-classic-area ptb--120">
                    <div className="container">
                        <BlogClassicOne SingleColumn="col-lg-6 col-md-6" />
                    </div>
                </div>
                {/* End Blog Classic Area */}
            </Fragment>
        )
    }
}

export default BlogTwoColumn;
