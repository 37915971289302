import React, { Component } from "react";
import CircleBox from "../../iconbox/CircleBox";
import IconBox2 from "../../iconbox/IconBox2";

class IdeasApp extends Component{
    render(){
        return(
            <div className="innoviatives-ideas-area pb--50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <CircleBox number="01" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--15 mt--55">
                                <h3 className="heading mb--30 fontWeight600">Innovative Ideas for Apps</h3>
                            </div>
                        </div>
                    </div>
                    <IconBox2 />
                </div>
            </div>
        )
    }
}
export default IdeasApp;