import React, { Component } from "react";

import ServiceFour from "../../service/ServiceFour";


class AgencyService extends Component{
    render(){
        let description = 'Welcome to AeroLand',
            title = 'Offer your business with the best assistance for growth.';
        return(
            <React.Fragment>
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title--3 text-center mb--80">
                                    <span>{description}</span>
                                    <h2 className="title">{title}</h2>
                                </div>
                            </div>
                        </div>
                        <ServiceFour />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AgencyService;