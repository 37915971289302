import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Portfolio from "./components/Portfolio";



class PortfolioWideGrid extends Component{
    render(){
        return(
            <Fragment>
                <Helmet>
                    <title>PORTFOLIO WIDE GRID || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'PORTFOLIO WIDE GRID'} />
                {/* End Breadcrumb Area */}

                {/* Start Portfolio Grid Wide */}
                <div className="portfolio-wide-grid-area ptb--120">
                    <div className="wrapper">
                        <div className="row plr--45">
                            <Portfolio SingleColumn="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-12" PortfolioStyle="portfolio" startItem="0" col="8" />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-button mt--70 text-center">
                                    <button className="ar-button style-flat solid-style" type="submit">
                                        <span className="button-text">View More</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Grid Wide */}


            </Fragment>
        )
    }
}
export default PortfolioWideGrid;