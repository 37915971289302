import React, { Component } from "react";
import {Helmet} from 'react-helmet';

import Breadcrumb from "../components/common/Breadcrumb";
import BoxIconOne from "./component/box-icon/BoxIconOne";
import BoxIconTwo from "./component/box-icon/BoxIconTwo";
import BoxIconThree from "./component/box-icon/BoxIconThree";
import BoxIconFour from "./component/box-icon/BoxIconFour";
import BoxIconFive from "./component/box-icon/BoxIconFive";
import BoxIconSix from "./component/box-icon/BoxIconSix";
import BoxIconSeven from "./component/box-icon/BoxIconSeven";
import BoxIconEight from "./component/box-icon/BoxIconEight";
import BoxIconNine from "./component/box-icon/BoxIconNine";


class BoxIcon extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>BOX ICON || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'BOX ICON'} />
                {/* End Breadcrumb Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon ptb--120 thine-line bg_color--1">
                    <div className="container">
                        <div className="row">
                            <BoxIconOne />
                        </div>
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon box-icon-two-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <BoxIconTwo />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pb--120 pt--90 bg_color--5">
                    <div className="container">
                        <BoxIconThree />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon ptb--120 bg_color--1">
                    <div className="container">
                        <BoxIconFour />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pb--120 pt--90 bg_color--5">
                    <div className="container">
                        <BoxIconFive />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pb--120 pt--90 bg_color--1">
                    <div className="container">
                        <BoxIconSix />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <BoxIconSeven />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <BoxIconEight />
                    </div>
                </div>
                {/* End BoxIcon Area */}

                {/* Start BoxIcon Area */}
                <div className="box-icon pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <BoxIconNine />
                    </div>
                </div>
                {/* End BoxIcon Area */}
            </div>
        )
    }
}
export default BoxIcon;
