import React, { Component } from "react";
import Wave from 'react-wavify'



class Application extends Component {
    render () {
        return (
            <div className="service-wavify">
                <Wave fill='#f6f5f9'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 80,
                        speed: 0.15,
                        points: 3,
                        bones: 3,
                        background: '#ffffff'
                    }}
                />
            </div>
        )
    }
}

export default Application









