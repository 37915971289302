// React Required
import './index.scss';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux'
import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

// Demo home pages
import Demo from './page-demo/Demo';
import Branding from './home/Branding';
import Sass from './home/Sass';
import AppShowcase from './home/AppShowcase';
import Software from './home/Software';
import StartUps from './home/StartUps';
import Payment from './home/Payment';
import AppLanding from './home/AppLanding';
import DigitalAgency from './home/DigitalAgency';
import Ebook from './home/Ebook';
import Clinic from './home/Clinic';
import DigitalStore from './home/DigitalStore';
// Inner pages

import Maintenance from "./pages/Maintenance";
import NotFound from "./pages/NotFound";

// Elements pages
import Button from './elements/Button';
import Accordion from './elements/Accordion';
import AttributeList from './elements/AttributeList';
import BoxIcon from './elements/BoxIcon';
import CallToAction from './elements/CallToAction';
import Dividers from './elements/Dividers';
import Counters from './elements/Counters';
import Gradation from './elements/Gradation';
import MessageBox from './elements/MessageBox';
import PopupVideo from './elements/PopupVideo';
import ProgressBar from './elements/ProgressBar';
import Lists from './elements/Lists';
import SocialShare from './elements/SocialShare';
import Gallery from './elements/Gallery';
import Team from './elements/Team';
import PricingBox from './elements/PricingBox';
import Tabs from './elements/Tabs';
import Contact from './elements/Contact';
import Testimonial from './elements/Testimonial';
import Countdown from './elements/Countdown';

// Blog pages
import BlogClassic from "./blog/BlogClassic";
import BlogDetails from "./blog/BlogDetails";
import BlogTwoColumn from "./blog/BlogTwoColumn";


// Blog pages
import PortfolioGrid from "./portfolio/PortfolioGrid";
import PortfolioWideGrid from "./portfolio/PortfolioWideGrid";
import PortfolioGridCaption from "./portfolio/PortfolioGridCaption";
import PortfolioCustomImage from "./portfolio/PortfolioCustomImage";
import PortfolioDetails from "./portfolio/PortfolioDetails";
import PortfolioDetailsTwo from "./portfolio/PortfolioDetailsTwo";

import Layout from './components/App';
import store from "./store";


class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {/* Start home pages */}
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Demo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/branding`} component={Branding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/sass`} component={Sass}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/app-showcase`} component={AppShowcase}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/software`} component={Software}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startups`} component={StartUps}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/payment`} component={Payment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/app-landing`} component={AppLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/ebook`} component={Ebook}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clinic`} component={Clinic}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/digital-store`} component={DigitalStore}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/maintenance`} component={Maintenance}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/not-found`} component={NotFound}/>
                        {/* End home pages */}

                        <Layout>
                            {/* Start Elements pages */}
                            <Route exact path={`${process.env.PUBLIC_URL}/button`} component={Button}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/accordion`} component={Accordion}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/attribute-list`}
                                   component={AttributeList}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/box-icon`} component={BoxIcon}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/call-to-action`}
                                   component={CallToAction}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/dividers`} component={Dividers}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/gradation`} component={Gradation}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/message-box`} component={MessageBox}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/popup-video`} component={PopupVideo}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/progress-bar`} component={ProgressBar}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/lists`} component={Lists}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/social-share`} component={SocialShare}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/pricing-box`} component={PricingBox}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/tabs`} component={Tabs}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/countdown`} component={Countdown}/>
                            {/* End Elements pages */}

                            {/* Start Blog pages */}
                            <Route exact path={`${process.env.PUBLIC_URL}/blog-classic`} component={BlogClassic}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/blog-two-column`}
                                   component={BlogTwoColumn}/>

                            <Route exact path={`${process.env.PUBLIC_URL}/blog-classic/:post_id`}
                                   component={BlogDetails}/>

                            {/* Start Blog pages */}
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-grid`}
                                   component={PortfolioGrid}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-grid-wide`}
                                   component={PortfolioWideGrid}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-grid-caption`}
                                   component={PortfolioGridCaption}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-custom-image`}
                                   component={PortfolioCustomImage}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`}
                                   component={PortfolioDetailsTwo}/>

                            {/* End Blog pages */}

                            {/* Start Inner pages */}
                            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-grid/:post_id`}
                                   component={PortfolioDetails}/>
                            {/* End Inner pages */}
                        </Layout>
                    </Switch>
                </BrowserRouter>
            </Provider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));



