import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import CountersOne from "./component/counters/CountersOne";
import CountersTwo from "./component/counters/CountersTwo";
import CountersFour from "./component/counters/CountersFour";

class Counters extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Counters || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
               <Breadcrumb title={'Counters'} />
                {/* End Breadcrumb Area */}
                
                {/* Start CounterUp Area */}
                <div className="counterup-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400">Style 01</h2>
                                </div>
                            </div>
                        </div>
                        <CountersOne />
                    </div>
                </div>
                {/* End CounterUp Area */}
                
                {/* Start CounterUp Area */}
                <div className="counterup-area ptb--120 theme-gradient">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400 text-white">Style 02</h2>
                                </div>
                            </div>
                        </div>
                        <CountersTwo />
                    </div>
                </div>
                {/* End CounterUp Area */}
                
                {/* Start CounterUp Area */}
                <div className="counterup-area ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2 className="fontWeight400">Style 03</h2>
                                </div>
                            </div>
                        </div>
                        <CountersFour />
                    </div>
                </div>
                {/* End CounterUp Area */}




            </div>
        )
    }
}
export default Counters;