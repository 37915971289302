import React , { Component } from "react";
import {Helmet} from 'react-helmet';
import HeaderTwo from '../components/header/HeaderTwo';
import Eservice from '../components/layouts/ebook/Eservice';
import Chanters from '../components/layouts/ebook/Chanters';
import TestimonialTwo from '../elements/component/testimonial/TestimonialTwo';
import CalltoActionThree from '../elements/component/callto-action/CalltoActionThree';
import FooterFive from '../components/footer/FooterFive';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class Ebook extends Component{
    render(){
        return(
            <div className="page-template-boxed-with-corner">
                <div className="page-template-boxed-with-corner-background boxed">
                    <div className="site">
                        <Helmet>
                            <title>Ebook || Aeroland React Multipurpose React Template</title>
                            <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                        </Helmet>

                        {/* Start Header Area */}
                        <HeaderTwo />
                        {/* End Header Area */}

                        {/* Start Slider Area */}
                        <div className="slider-area slider--ebook bg_image bg_image--17 d-flex align-items-center">
                            <div className="row w-100">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="content h-100 d-flex align-items-center">
                                        <div className="inner">
                                            <span>it's time to change something</span>
                                            <h2 className="title">Learn to Design Beautiful & Simple Websites</h2>
                                            <button className="ar-button style-flat" type="submit"><span className="button-text">Get the book your way</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt_md--30 mt_sm--30 col-12">
                                    <div className="thumbnail text-left text-lg-right plr_sm--40 plr_md--40">
                                        <img src={require('../assets/images/slider/ebook-01.png').default} alt="Slider Images"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider Area */}

                        {/* Start Service Area */}
                        <div className="e-service-area pt--130 pb--50">
                            <Eservice />
                        </div>
                        {/* End Service Area */}
                        
                        {/* Start Chanters Area */}
                        <Chanters />
                        {/* End Chanters Area */}

                        {/* Start Testimonial Area */}
                        <div className="e-testimonial-area bg_color--5 pb--120">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="space-top"></div>
                                        <div className="section-title--3 text-center mb--55 mb_md--20 mb_sm--20">
                                            <span>Write & read reviews</span>
                                            <h2 className="title fontWeight600">Right from the word go</h2>
                                        </div>
                                    </div>
                                </div>
                                <TestimonialTwo />
                                <div className="view-more-button mt--70 mt_sm--10 mt_md--10 text-center">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">View all reviews</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* End Testimonial Area */}

                        {/* Start Call TO Action Area */}
                        <CalltoActionThree />
                        {/* End Call TO Action Area */}

                        {/* Start Footer Area */}
                        <FooterFive />
                        {/* End Footer Area */}

                        {/* Start Back To Top */}
                        <div className="backto-top">
                            <ScrollToTop showUnder={160}>
                                <FiChevronUp />
                            </ScrollToTop>
                        </div>
                        {/* End Back To Top */}

                    </div>
                </div>
            </div>
        )
    }
}
export default Ebook;