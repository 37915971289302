import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Accordion01 from "../components/accordion/Accordion";

class Accordion extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Accordion || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Accordions & Toggles'} />
                {/* End Breadcrumb Area */}

                {/* Start Accordion Area */}
                <div className="accordion-area ptb--120 bg-color" data-bg-color="#ffffff">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="section-title mb--40">
                                    <h3>Accordions</h3>
                                </div>
                                <div className="accordion-wrap">
                                    <Accordion01 />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="section-title mb--40">
                                    <h3>Toggles</h3>
                                </div>
                                <div className="accordion-wrap">
                                    <Accordion01 />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Accordion Area */}
                 
            </div>
        )
    }
}
export default Accordion;
