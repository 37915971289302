import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Iconbox from '../components/iconbox/Iconbox';


class Gradation extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Gradation || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Dividers'} />
                {/* End Breadcrumb Area */}

                {/* Start Gradation Area */}
                <div className="gradation-area ptb--120">
                    <Iconbox />
                </div>
                {/* End Gradation Area */}
            </div>
        )
    }
}
export default Gradation;