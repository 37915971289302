import React from "react";

export const ClinicListItem  = [
  'Dedicated, passionate and comfortable services',
  'Affordable pricing plans for individuals',
  'Premium customer support',
  'Delivery time is accurate and scheduled',
]

/*=======================
    Pricing BOX Five 
========================*/

const FeatureClassic = [
  '03 projects',
  'Power And Predictive Dialing',
  'Quality & Customer Experience',
]

const FeatureAdvance = [
  'Unlimited project',
  'Power And Predictive Dialing',
  'Quality & Customer Experience',
  '24/7 phone and email support',
]

const FeaturePlus = [
  '50 projects',
  'Power And Predictive Dialing',
  'Quality & Customer Experience',
]

export const PricingBoxFiveData = [
    {
        image: '01',
        title: 'CLASSIC PACKAGE',
        PricingBoxFiveFeature: FeatureClassic.map( (name, i) => (
            <li key={i}>{name}</li>
        )),
    },
    {
        image: '02',
        title: 'ADVANCED PACKAGE',
        PricingBoxFiveFeature: FeatureAdvance.map( (name, i) => (
            <li key={i}>{name}</li>
        )),
    },
    {
        image: '03',
        title: 'PLUS PACKAGE',
        PricingBoxFiveFeature: FeaturePlus.map( (name, i) => (
            <li key={i}>{name}</li>
        )),
    },

]

/*=======================
    Data Digital Store 
=========================*/

export const DigitalStoreData = [
    {
        image: '01',
        title: 'Iphone XS Mockup',
        category: 'For Sketch, Photoshop',
        description: 'The web is teeming with highly realistic iPhone X, XS, XR mockups with outstanding quality.',
        oldPrice: '',
        newPrice: '$29'
    },
    {
        image: '02',
        title: 'Ipad Pro Mockup',
        category: 'For After effects',
        description: 'To showcase a website, an app, or any kind of design in action, free iPad PSD mockup templates.',
        oldPrice: '$39',
        newPrice: '$19'
    },
    {
        image: '03',
        title: 'Notebook Mockup',
        category: 'For Sketch, Photoshop',
        description: 'Are you looking for notebook mockup vectors or photos? We have 5307 free resources for you.',
        oldPrice: '',
        newPrice: '$19'
    },
    {
        image: '04',
        title: 'Ipad Mockup Pack',
        category: 'For Sketch, Photoshop',
        description: 'Save time with this exhaustive list of more than 150 free PSD logo and book mockups for your next graphic design project.',
        oldPrice: '',
        newPrice: '$19'
    },
    {
        image: '05',
        title: 'Iphone C Mockup',
        category: 'For Sketch, Photoshop',
        description: 'Fully-scalable vector shape iPhone 5C PSD mockup template in colorful green, blue, yellow, pink, and white.',
        oldPrice: '',
        newPrice: '$19'
    },
    {
        image: '06',
        title: 'United Pixel Mockup',
        category: 'For Sketch, Photoshop',
        description: 'Born out of some big ideas, AeroLand has always understood people\'s pride not only in their work but also the.',
        oldPrice: '',
        newPrice: '$19'
    },
    {
        image: '07',
        title: 'Iphone X mockup',
        category: 'For Sketch, Photoshop',
        description: 'The web is teeming with highly realistic iPhone X, XS, XR mockups with outstanding quality.',
        oldPrice: '$5',
        newPrice: '$0'
    }
]



/*=====================
    Blog Comment 
=======================*/
export const BLogComment = [
    {
        images: '01',
        title: 'Edna Watson',
        commentDate: 'November 14, 2018 at 4:24 am',
        description: 'Working with AeroLand is like having a family member who can fix everything. They know what you need, exactly when you need it.',
        CommentToComment: ''
    },
    {
        images: '02',
        title: 'James Scott',
        commentDate: 'November 14, 2018 at 4:24 am',
        description: 'Working with AeroLand is like having a family member who can fix everything. They know what you need, exactly when you need it.',
        CommentToComment: 'CommentToComment'
    },
    {
        images: '03',
        title: 'Harry Ferguson',
        commentDate: 'November 14, 2018 at 4:24 am',
        description: 'Working with AeroLand is like having a family member who can fix everything. They know what you need, exactly when you need it.',
        CommentToComment: 'CommentToComment'
    },
    {
        images: '01',
        title: 'James Scott',
        commentDate: 'November 14, 2018 at 4:24 am',
        description: 'Working with AeroLand is like having a family member who can fix everything. They know what you need, exactly when you need it.',
        CommentToComment: ''
    },
]

















