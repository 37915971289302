import React, { Component } from "react";
import Slider from "react-slick";
import {testimonialOne} from "../../../home/script";

class TestimonialOne extends Component{
    render(){
        return(
            <div className="row">
                <div className="col-lg-12">
                    <div className="testimonial-activation-wrapper">
                        <Slider {...testimonialOne}>
                            {/* Start Single Slider */}
                            <div className="single-testimonial testimonial-style--2">
                                <div className="thumbnail">
                                    <img src={require('../../../assets/images/testimonial/client-01.png').default} alt="Testimonial Imgaes"/>
                                </div>
                                <div className="content">
                                    <p>” Their customer support was amazing. Their help was quick and gave me very clear instructions to follow! Thanks! ”</p>
                                    <h6>Rex Watson</h6>
                                    <span>Marketing</span>
                                </div>
                            </div>
                            {/* End Single Slider */}

                            {/* Start Single Slider */}
                            <div className="single-testimonial testimonial-style--2">
                                <div className="thumbnail">
                                    <img src={require('../../../assets/images/testimonial/client-02.png').default} alt="Testimonial Imgaes"/>
                                </div>
                                <div className="content">
                                    <p>” Their customer support was amazing. Their help was quick and gave me very clear instructions to follow! Thanks! ”</p>
                                    <h6>Rex Watson</h6>
                                    <span>Marketing</span>
                                </div>
                            </div>
                            {/* End Single Slider */}

                            {/* Start Single Slider */}
                            <div className="single-testimonial testimonial-style--2">
                                <div className="thumbnail">
                                    <img src={require('../../../assets/images/testimonial/client-03.png').default} alt="Testimonial Imgaes"/>
                                </div>
                                <div className="content">
                                    <p>” Their customer support was amazing. Their help was quick and gave me very clear instructions to follow! Thanks! ”</p>
                                    <h6>Rex Watson</h6>
                                    <span>Marketing</span>
                                </div>
                            </div>
                            {/* End Single Slider */}
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}
export default TestimonialOne;
