import React, { Component } from "react";
import ServiceThree  from "../../service/ServiceThree";

class PictureService extends Component{
    render(){
        return(
            <div className="service-area branding-service bg_color--1 pt--50">
               <ServiceThree />
            </div>
        )
    }
}
export default PictureService;
