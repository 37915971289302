import React, { Component } from "react";
import PricingTable from "../../pricingtable/PricingTable";
import Wavify2 from '../../plugins/Wavify';

class BrandPricing extends Component{
    render(){
        return(
            <div className="brand-pricing-table-area position-relative wayvify-style--3 ptb--120 bg_color--4">
                <Wavify2 />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--35">
                                <h3 className="heading mb--30 fontWeight600">Affordable for everyone!</h3>
                                <p>If you are planning on developing a product landing app or website, take a look <br /> at this beautiful-crafted and error-free WordPress theme.</p>
                            </div>
                        </div>
                    </div>
                    <PricingTable />
                </div>
            </div>
        )
    }
}
export default BrandPricing;