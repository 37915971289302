import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BoxIconNine extends Component{
    render(){
        return(
            <Row>
                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                        <svg>
                            <path fill="none" d="M32,1c14.359,0,27,12.641,27,27S46.359,55,32,55
                                c-10,0-13-4-13-4"></path>
                            <path fill="none" d="M12,28A20,20 0,1,1 52,28A20,20 0,1,1 12,28"></path>
                            <path fill="none" d="M32,54L32,64"></path>
                            <path fill="none" d="M22,63L42,63"></path>
                        </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Free Consulting</h5>
                            <p>Consult with the team of our specialists about your issues for free of charge.</p>
                        </div>
                    </div> 
                </Col> 
                {/* End Single Box */}

                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                            <svg>
                                <path fill="none" d="M1,63L1,2L62,63Z"></path>
                                <path fill="none" d="M14,54L14,30L38,54Z"></path>
                                <path fill="none" d="M8,22L2,22"></path>
                                <path fill="none" d="M6,30L2,30"></path>
                                <path fill="none" d="M8,38L2,38"></path>
                                <path fill="none" d="M6,46L2,46"></path>
                                <path fill="none" d="M8,54L2,54"></path>
                                <path fill="none" d="M25,1L19,7L55,43L63,45L61,37Z"></path>
                                <path fill="none" d="M25,13L31,7"></path>
                            </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Great Knowledgebase</h5>
                            <p>Go to thememove.com for valuable knowledge sharing and experiences.</p>
                        </div>
                    </div>
                </Col> 
                {/* End Single Box */}

                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                        <svg>
                            <g>
                                <path fill="none" d="M53.92,10.081
                                    c12.107,12.105,12.107,31.732,0,43.838c-12.106,12.108-31.734,12.108-43.84,0c-12.107-12.105-12.107-31.732,0-43.838
                                    C22.186-2.027,41.813-2.027,53.92,10.081z"></path>
                                <path fill="none" d="M32,12L32,32L41,41"></path>
                                <path fill="none" d="M4,32L8,32"></path>
                                <path fill="none" d="M56,32L60,32"></path>
                                <path fill="none" d="M32,60L32,56"></path>
                                <path fill="none" d="M32,8L32,4"></path>
                            </g>
                        </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Excellent Support</h5>
                            <p>Our customer support team is just a click away and ready to assist you at any time.</p>
                        </div>
                    </div>
                </Col> 
                {/* End Single Box */}

                {/* Start Single Box */}
                <Col className="mt--30" lg="3" md="6" sm="12" xs="12">
                    <div className="box-icon--9">
                        <div className="svg-inner">
                        <svg>
                            <path fill="none" d="M61,44L61,55L1,55L1,15L61,15L61,26"></path>
                            <path fill="none" d="M6,9L54,9L54,15"></path>
                            <path fill="none" d="M6,9c-2.762,0-5,2.239-5,5"></path>
                            <path fill="none" d="M43.125,26c-4.972,0-9,4.029-9,9c0,4.97,4.028,9,9,9
                                H63V26H43.125z"></path>
                            <path fill="none" d="M41,35A3,3 0,1,1 47,35A3,3 0,1,1 41,35"></path>
                        </svg>
                        </div>
                        <div className="content">
                            <h5 className="heading">Mobile Friendly</h5>
                            <p>With Aeroland, the adaptiveness of your website will make it display well on any devices.</p>
                        </div>
                    </div>
                </Col> 
                {/* End Single Box */}
            </Row>
        )
    }
}

export default BoxIconNine;