import React, { Component } from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class BoxIconFour extends Component{
    render(){
        let Data = [
            {
                icon: '01',
                title: 'Web Development',
                desc: 'Our technical experts have a flair for developing clean-coded &amp; seamless websites based on customers’ needs '
            },

            {
                icon: '02',
                title: 'Social Media',
                desc: 'Our presence on a wide range of social platforms leads to better marketing results, larger customer base &amp; opportunities.'
            },

            {
                icon: '03',
                title: 'SEO Marketing',
                desc: 'From meticulous research on search engine optimization, we have created strategies to increase the efficiency. '
            },

            {
                icon: '04',
                title: 'Keyword Search',
                desc: 'Studies showed that the choice of appropriate keyword for your sites and posts helps increase site ranking.'
            },

            {
                icon: '05',
                title: 'Email Optimization',
                desc: 'Keep your audiences in touch with your company’s latest news, upcoming events and new product launch via emails.'
            },

            {
                icon: '06',
                title: 'Web Design',
                desc: 'We are capable of designing a whole new website for businesses and companies or even redesigning old sites.'
            }
        ]

        let DataList = Data.map((val , i) => {
            return(
                <Col lg="4" md="6" sm="6" xs="12" key={i}>
                    <div className="box-icon--4">
                        <div className="icon">
                            <img src={require(`../../../assets/images/box-img/startup-box-icon-${val.icon}.png`).default} alt="App Landing"/>
                        </div>
                        <div className="content">
                            <h5 className="heading">{val.title}</h5>
                            <p>{val.desc}</p>
                        </div>
                    </div>
                </Col>    
            )
        })
        return(
            <Row className="mt--30">
                {DataList}
            </Row>
        )
    }
}

export default BoxIconFour;

