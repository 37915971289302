import React, { Component , Fragment } from "react";
import {Helmet} from 'react-helmet';
import HeaderTwo from '../components/header/HeaderTwo';
import { SectionTitle ,SectionTitle2 } from "../components/sectiontitle/SectionTitle";
import BoxIconEight from "../elements/component/box-icon/BoxIconEight";
import ContactTwo from "../elements/component/contact/ContactTwo";
import CountersOne from "../elements/component/counters/CountersOne";
import Footer from '../components/footer/Footer';
import {ClinicListItem ,PricingBoxFiveData} from "./data";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";


class Clinic extends Component{
    render(){
        let PricingBoxSlice = PricingBoxFiveData.slice(0, 3);
        return(
           <Fragment>
                <Helmet>
                    <title>Clinic || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderTwo />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area slider--clinic">
                    <div className="clinic-hero-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="content">
                                        <div className="inner text-center">
                                            <h1>Want an appointment?</h1>
                                            <p>Manage healthcare administration end-to-end with healthcare app solutions</p>
                                            <form action="#">
                                                <div className="form-wrapper">

                                                    <div className="input-box">
                                                        <i className="fa fa-user-alt"></i>
                                                        <input type="text" placeholder="Your Name" />
                                                    </div>
                                                    <div className="input-box">
                                                        <i className="fa fa-phone"></i>
                                                        <input type="text" placeholder="Your Phone Number" />
                                                    </div>

                                                    <div className="input-box">
                                                        <button className="ar-button style-flat"><span className="button-text">Request</span></button>
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clinic-slide-bottom">
                            <div className="svg-bottom-image">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
                                    <polygon points="0,0 50,100 100,0 101,100 0 101"></polygon>
                                </svg>
                            </div>
                            <div className="clinic-hero-image">
                                <img src={require('../assets/images/slider/clinic.png').default} alt="Slider Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area */}

                {/* Clinic Service Area */}
                <div className="clinic-service-area ptb--120">
                    <div className="container">
                        <div className="row mb--70 mb_lg--40 mb_md--0 mb_sm--0">
                            <div className="col-lg-12">
                                <SectionTitle textAlign="text-center" subtitle="What’s included?" title="Experienced staff. Excellent care." />
                            </div>
                        </div>
                        <BoxIconEight />
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Clinic Portfolio Area */}
                <div className="clinic-portfolio-area clinic-bg-gradient ptb--120">
                    <div className="container">
                        <div className="row mb--60 mb_md--0 mb_sm--0">
                            <div className="col-lg-12">
                                <SectionTitle textAlign="text-center" subtitle="How AeroLand works?" title="AeroLand helps you find the right help" />
                            </div>
                        </div>
                        {/* Start Single Portfolio */}
                        <div className="row align-items-center clinic-portfolio">
                            <div className="col-lg-6 mt--40">
                                <div className="thumbnail">
                                    <img src={require('../assets/images/aeroland/clinic-image-01.png').default} alt="App Landing"/>
                                </div>
                            </div>
                            <div className="col-lg-5 mt--40">
                                <div className="inner">
                                    <SectionTitle2 textAlign="text-left" subtitle="STEP 01" title="Instant appointment with doctors" />
                                    <div className="description">
                                        <p className="mb--40">We’ve combined a new kind of doctor’s experience that blends the highest level of health care with exceptional service. People can enroll membership by paying $129.</p>
                                        <div className="portfolio-button">
                                            <button className="ar-button style-flat with-icon" type="submit">
                                                <span className="button-text">Find out more <i className="fa fa-arrow-right"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Portfolio */}

                        {/* Start Single Portfolio */}
                        <div className="row align-items-center clinic-portfolio clinic-portfolio--right mt--100 mt_md--30 mt_sm--30">
                            <div className="col-lg-5 order-2 order-lg-1 mt--40">
                                <div className="inner">
                                    <SectionTitle2 textAlign="text-left" subtitle="STEP 02" title="First-rate Healthcare Providers" />
                                    <div className="description">
                                        <ul className="list-style--1">
                                            {ClinicListItem.map((name, index) => (
                                                <li className="font--18" key={ index }><i className="fa fa-check"></i> {name}</li>
                                            ))}
                                        </ul>
                                        <div className="portfolio-button mt--45">
                                            <button className="ar-button style-flat with-icon" type="submit">
                                                <span className="button-text">Find out more <i className="fa fa-arrow-right"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 mt--40">
                                <div className="thumbnail">
                                    <img src={require('../assets/images/aeroland/clinic-image-02.png').default} alt="App Landing"/>
                                </div>
                            </div>
                        </div>
                        {/* End Single Portfolio */}


                        {/* Start Single Portfolio */}
                        <div className="row align-items-center clinic-portfolio mt--100 mt_md--30 mt_sm--30">
                            <div className="col-lg-6 mt--40">
                                <div className="thumbnail">
                                    <img src={require('../assets/images/aeroland/clinic-image-03.png').default} alt="App Landing"/>
                                </div>
                            </div>
                            <div className="col-lg-5 mt--40">
                                <div className="inner">
                                    <SectionTitle2 textAlign="text-left" subtitle="STEP 03" title="Introduct App for AeroLand" />

                                    <div className="description">
                                        <p className="mb--40">There’s no need to be a technical professional to create sites with AeroLand. With simple guides, everything is easy! We deliver the best services for app design and coding.</p>
                                        <div className="portfolio-button">
                                            <div className="button-group">
                                                <button className="button-image" type="submit">
                                                    <img src={require('../assets/images/logo/app-store-dark.jpg').default} alt="Logo Images" />
                                                </button>
                                                <button className="button-image" type="submit">
                                                    <img src={require('../assets/images/logo/google-play-dark.jpg').default} alt="Logo Images" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Portfolio */}

                    </div>
                </div>
                {/* End Clinic Portfolio Area */}


                {/* Start Counterup Area */}
                <div className="clinic-counterup-area ptb--120 bg_color--5">
                     <div className="container">
                         <div className="row">
                             <div className="col-lg-12">
                                <SectionTitle textAlign="text-center" subtitle="Fun Facts" title="No stories, facts only" />
                             </div>
                         </div>
                         <CountersOne />
                     </div>                           
                </div>
                {/* End Counterup Area */}


                {/* Start Pricing Table Area */}
                <div className="pricing-table-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle textAlign="text-center" subtitle="Price plan" title="Affordable price for everyone!" />
                            </div>
                        </div>  
                        <div className="row">
                            {PricingBoxSlice.map( (value , index) =>(
                                <div className="col-lg-4 col-md-6 col-12 mt--30" key={index}>
                                    <div className="pricing-box--5">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <img src={require(`../assets/images/aeroland/pricing-05-image-${value.image}.png`).default} alt=""/>
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{value.title}</h4>
                                                <div className="feature-list">
                                                    <ul className="pricing-feature-list">
                                                        {value.PricingBoxFiveFeature}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="pricing-footer">
                                                <button>See Pricing</button>
                                            </div>
                                        </div> 
                                    </div> 
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                {/* End Pricing Table Area */}

                {/* Start Newsletter Area */}
                <ContactTwo />
                {/* End Newsletter Area */}   

                {/* Start Footer Area */}
                <Footer />
                {/* End Footer Area */} 

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */} 

           </Fragment>
        )
    }
}

export default Clinic;