import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import {FiDownload} from "react-icons/fi";

import HeaderOne from '../components/header/HeaderOne';
import Iconbox from '../components/iconbox/Iconbox';
import Marketing from '../components/layouts/sass/Marketing';
import ResponsiveApp from '../components/layouts/sass/ResponsiveApp';
import BusinessService from '../components/layouts/sass/BusinessService';
import DownloadApp from '../components/layouts/sass/DownloadApp';
import Funfacts from '../components/layouts/sass/Funfacts';
import SassTestimonial from '../components/layouts/sass/SassTestimonial';
import Brand from '../components/brand/Brand';
import DigitalApp from '../components/layouts/sass/DigitalApp';
import Footer from '../components/footer/Footer';
import Wavify from '../components/plugins/Wavify';
import {sliderdefault} from './script';
import Slider from "react-slick";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";

class Sass extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Sass || Aeroland React Multipurpose React Template</title>
                    <meta name="description"
                          content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template."/>
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne/>
                {/* End Header Area */}

                {/* Start Slider Area */}
                <Slider {...sliderdefault} className="slick-default-dot slick-animation">
                    {/* Start Single Slide  */}
                    <div className="slider-area slider--sass bg_image bg_image--1">
                        <div className="slide slider-fixed-height text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="content">
                                            <h2>Free App Download Now</h2>
                                            <p>Responsive app for all kinds of purposes.</p>
                                            <button className="ar-button secondary style-flat with-left" type="submit">
                                                <span className="button-text"><FiDownload/> Standard Style</span>
                                            </button>
                                        </div>
                                        <div className="thumbnail">
                                            <img src={require('../assets/images/mokup/sass-thumb-1.png').default} alt="Sass Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Slide  */}
                    {/* Start Single Slide  */}
                    <div className="slider-area slider--sass bg_image bg_image--1">
                        <div className="slide slider-fixed-height text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="content">
                                            <h2>Free App Download Now</h2>
                                            <p>Responsive app for all kinds of purposes.</p>
                                            <button className="ar-button secondary style-flat with-left" type="submit">
                                                <span className="button-text"><FiDownload/> Standard Style</span>
                                            </button>
                                        </div>
                                        <div className="thumbnail">
                                            <img src={require('../assets/images/mokup/sass-thumb-2.png').default} alt="Sass Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Slide  */}
                    {/* Start Single Slide  */}
                    <div className="slider-area slider--sass bg_image bg_image--1">
                        <div className="slide slider-fixed-height text-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="content">
                                            <h2>Free App Download Now</h2>
                                            <p>Responsive app for all kinds of purposes.</p>
                                            <button className="ar-button secondary style-flat with-left" type="submit">
                                                <span className="button-text"><FiDownload/> Standard Style</span>
                                            </button>
                                        </div>
                                        <div className="thumbnail">
                                            <img src={require('../assets/images/mokup/sass-thumb-3.png').default} alt="Sass Images"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Slide  */}

                </Slider>

                {/* End Slider Area */}

                {/* Start Icon Box Area */}
                <div className="icon-box-area pb--70">
                    <Iconbox/>
                </div>
                {/* End Icon Box Area */}

                <section className="section-wrapper-wayvify wayvify-style--2 position-relative bg_color--5">
                    <Wavify/>
                    {/* Start Marketing Area */}
                    <Marketing/>
                    {/* End Marketing Area */}

                    {/* Start Responsive App Area */}
                    <ResponsiveApp/>
                    {/* End Responsive App Area */}
                </section>

                <section className="section-wrapper-wayvify position-relative bg_color--5">
                    <Wavify/>
                    {/* Start Business Service Area */}
                    <BusinessService/>
                    {/* End Business Service Area */}

                    {/* Start Download App Area */}
                    <DownloadApp/>
                    {/* End Download App Area */}
                </section>

                {/* Start Counterup Area */}
                <Funfacts/>
                {/* End Counterup Area */}

                {/* Start Testimonial Area */}
                <SassTestimonial/>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <Brand/>
                {/* End Brand Area */}

                {/* Start Digital App Area */}
                <DigitalApp/>
                {/* End Digital App Area */}

                <Footer/>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}

export default Sass;





