import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component{
    render(){
        const { title , parent} = this.props;
        return(
            <div className="breadcrumb-area breadcrumb-default">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content">
                                <div className="inner">
                                    <h1 className="page-title">{title}</h1>
                                    <ul className="breadcrumb-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent? <li className="breadcrumb-item" aria-current="page">{parent}</li>:''}
                                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Breadcrumb;