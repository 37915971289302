import React, {Component} from "react";
import {Helmet} from 'react-helmet';
import {FiChevronUp} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Footer from '../components/footer/Footer';
import HeaderTwo from '../components/header/HeaderTwo';
import BrandPricing from '../components/layouts/branding/BrandPricing';
import BusinessAnalitcs from '../components/layouts/branding/BusinessAnalitcs';
import CounterService from '../components/layouts/branding/CounterService';
import MeetingSeo from '../components/layouts/branding/MeetingSeo';
import Newsletter from '../components/layouts/branding/Newsletter';
import PictureService from '../components/layouts/branding/PictureService';
import Service from '../components/layouts/branding/Service';
import TrustedCompany from '../components/layouts/branding/TrustedCompany';

class Branding extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Branding || Aeroland React Multipurpose React Template</title>
                    <meta name="description"
                          content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template."/>
                </Helmet>

                {/* Start Header Area */}
                <HeaderTwo/>
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area">
                    {/* Start Single Slide */}
                    <div className="slide slider--style--1 slider-fixed-height bg_image bg_image--15">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="slide-text-centent">
                                    <h2 className="title">Executive branding <br/> campaign</h2>
                                    <p className="description">True branding starts from within. We create and adopt
                                        unique branding resolution to strengthen your business.</p>
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">Get a Demo</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area */}

                {/* Start Service Area */}
                <Service/>
                {/* End Service Area */}

                <BusinessAnalitcs/>

                {/* Start Meeting Seo */}
                <MeetingSeo/>
                {/* End Meeting Seo */}

                {/* Start Picture Service Area */}
                <PictureService/>
                {/* End Picture Service Area */}

                {/* Start Counter Up Table */}
                <CounterService/>
                {/* End Counter Up Table */}

                {/* Start Brand Pricing Table */}
                <BrandPricing/>
                {/* End Brand Pricing Table */}

                {/* Start Trusted Company */}
                <TrustedCompany/>
                {/* End Trusted Company */}

                {/* Start Nesletter Area */}
                <Newsletter/>
                {/* End Nesletter Area */}

                {/* Start Footer Area */}
                <Footer/>
                {/* End Footer Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}

export default Branding;








