import React, { Component } from "react";
import { Link } from 'react-router-dom'

class DigitalApp extends Component{
    render(){
        return(
            <div className="sass-digital-app ptb--120 bg_image bg_image--2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="heading mb--20 fontWeight400 text-white mb_sm--10 mb_md--10">Digital marketing app <br /> on the go!</h3>
                            <p className="font--18 text-white mb--40 mb_sm--20 mb_md--20">It’s easy to switch between your different devices</p>
                            <div className="digital-app-button">
                                <Link to={`${process.env.PUBLIC_URL}/sass`}>
                                    <img src={require('../../../assets/images/mokup/aeroland-button-app-store.jpg').default} alt="App landing" />
                                </Link>
                                <Link to={`${process.env.PUBLIC_URL}/sass`}>
                                    <img src={require('../../../assets/images/mokup/aeroland-button-google-play.jpg').default} alt="App landing" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DigitalApp;

