import React, { Component } from "react";
import ServiceTwo from "../../service/ServiceTwo";


class Service extends Component{
    render(){
        return (
            <div className="service-area bg-color ptb--120 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--10">
                                <h3 className="heading mb--10 fontWeight500">Expand Your Business</h3>
                                <p>Plan ahead to make progress and lead the trend.</p>
                            </div>
                        </div>
                    </div>
                    <ServiceTwo />
                </div>

            </div>
        )
    }
}

export default Service;
