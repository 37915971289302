import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import HeaderOne from '../components/header/HeaderOne';
import { FaPlayCircle } from "react-icons/fa";
import ModalVideo from 'react-modal-video'
import BoxIconFour from "../elements/component/box-icon/BoxIconFour";
import BoxIconTwo from "../elements/component/box-icon/BoxIconTwo";
import TabsOne from "../elements/component/tabs/TabsOne";
import CalltoActionOne from "../elements/component/callto-action/CalltoActionOne";
import TeamTwo from "../elements/component/team/TeamTwo";
import TabsThree from "../elements/component/tabs/TabsThree";
import CalltoActionFour from "../elements/component/callto-action/CalltoActionFour";
import Wavify from '../components/plugins/WavifyWhite';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Footer from '../components/footer/Footer';

class StartUps extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Start Ups || Aeroland React Multipurpose React Templateg</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Header Area */}
                <HeaderOne />
                {/* End Header Area */}

                {/* Start Slider Area */}
                <div className="slider-area">
                    {/* Start Single Slide */}
                    <div className="single-slide slider--startups bg_image fullscreen bg_image--5">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="slider-content">
                                    <div className="inner">
                                        <h1 className="text-white title">Get Your Sites <br /> A Kickstart Now!</h1>
                                        <p className="text-white font--18 description">There’s no need to be a technical professional to create sites with AeroLand. With simple guides, everything is easy!</p>
                                        <div className="button-group d-block d-sm-flex align-items-center">
                                            <button className="ar-button style-flat secondary" type="submit">
                                                <span className="button-text">Get Started</span>
                                            </button>
                                            <div className="aro-btn-second d-flex align-items-center">
                                                <h6 className="text-white mb--0">Questions?</h6> 
                                                <a className="link-hover color-4" href="#contact"> Talk to our team</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="thumbnail text-left">
                                    <img src={require('../assets/images/slider/startup-hero-image-reup.png').default} alt="Slider Images"/>
                                </div>
                            </div>
                        </div>
                        <div className="wavify-position">
                            <Wavify />
                        </div>
                    </div>
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area */}

                {/* Start Startup Service */}
                <div className="startups-service pt--80 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20">
                                    <h2>We’ve created enticing designs for <br /> startups and new businesses</h2>
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9No-FiEInLA' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-button" onClick={this.openModal}><FaPlayCircle /><span>Intro & demo video</span></button>
                                </div>
                            </div>
                        </div>
                        <BoxIconFour />
                        <div className="row mt--70 text-center">
                            <div className="col-lg-12">
                                <button className="ar-button style-flat with-icon" type="submit">
                                    <span className="button-text">Discover how it works <i className="fa fa-arrow-right"/></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Startup Service */}

                {/* Start Platform Area */}
                <div className="present-platform-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--45">
                                    <h2>Present over 50 platforms</h2>
                                    <p>We are present over a wide range of social media</p>
                                </div>
                            </div>
                        </div>
                        <BoxIconTwo />
                    </div>
                </div>
                {/* End Platform Area */}


                {/* Start Startups tabs Area */}
                <div className="startups-tabs-area startups-bg-images ptb--120 bg_color--1">
                    <CalltoActionOne />
                    <div className="container pt--120">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--60">
                                    <h2>We design powerful websites for <br /> landing your success.</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabsOne />
                </div>
                {/* End Startups tabs Area */}


                {/* Start Team Area */}
                <div className="our-tema-area theme-gradient pt--120 pb--110">
                    <TeamTwo />
                </div>
                {/* End Team Area */}

                {/* Start Tabs Area */}
                <div className="our-tabs-area startups-tabstwo-area pt--120 pb--20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--80 mb_lg--30 mb_sm--30 md_md--30">
                                    <h2>Developed for Business Growth</h2>
                                    <p>Offer your business with the best assistance for growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TabsThree />
                </div>
                {/* End Tabs Area */}

                 {/* Start Call TO Action */}
                 <div className="call-to-action-wrapper call-to-action-four-bg theme-gradient--2">
                    <CalltoActionFour />
                </div>
                {/* End Call TO Action */}
                <Footer />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </React.Fragment>
        )
    }
}


export default StartUps;
