import React, { Component } from "react";
import { withTranslate } from 'react-redux-multilingual';
import Headerdefault from "./header/Headerdefault";
import Footer from "./footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

class App extends Component{
    render(){
        return(
            <div>
                <Headerdefault headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {this.props.children}
                
                 {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <Footer />
            </div>
        )
    }
}
export default withTranslate(App);