import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaDownload } from "react-icons/fa";

class Download extends Component{
    render(){
        return(
            <div className="download-app-area">
                <Container>
                    <Row className="row--0 download-app-wrapper align-items-center">
                        <Col lg="6" md="6">
                            <div className="doenload-appinner ptb--50">
                                <div className="content">
                                    <h3>Free download of our app now</h3>
                                    <h5>Offer your business with the best assistance for growth.</h5>
                                    <a className="download-btn" href="/"><FaDownload className="icon" /><span>Get AeroLand</span></a>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="6" className="mt_sm--40">
                            <div className="thumbnail">
                                <img src={require('../../../assets/images/mokup/aeroland-macbook-01.png').default} alt="App Landing"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Download
