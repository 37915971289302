import React, { Component } from "react";
import PropTypes from "prop-types";

class TeamOne extends Component{
    render(){
        const{ sectionTitle } = this.props

        // Start Team Loop
        const allTeamData = this.props.teamData.map((team, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 text-center" key={index}>
                <div className="team">
                    <div className="thumbnail">
                        <img src={require(`../../../assets/images/team/team-${team.images}.png`).default} alt="Team Images"/>
                    </div>
                    <h4 className="title">{team.heading}</h4>
                    <p>{team.description}</p>
                    <ul className="social-icon large-icon">
                        <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        ));
        // End Team Loop    

        return(
            <React.Fragment>
                <div className="team-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--40 mb_sm--20">
                                    <h2>{sectionTitle}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {allTeamData}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

//Props Types
TeamOne.propTypes = {
    sectionTitle: PropTypes.string,
    teamData: PropTypes.array
};

//Default Props
TeamOne.defaultProps = {
    sectionTitle: "The best team available",
    
    teamData: [
        {
            images: "01",
            heading: "Chad Brewer",
            description: "Director"
        },
        {
            images: "02",
            heading: "Dollie Horton",
            description: "Marketing"
        },
        {
            images: "03",
            heading: "Fannie Garcia",
            description: "Director"
        },
        {
            images: "04",
            heading: "Dollie Horton",
            description: "Marketing"
        },
    ]
};

export default TeamOne;
