import React, { Component } from "react";

class CalltoActionTwo extends Component{
    render(){
        return(
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <div className="content mb--45">
                                    <h3 className="font--40 mb--25">Ready to get started?</h3>
                                    <h5 className="body-color fontWeight400">Daily position tracking & competitor tracking, anytime, anywhere!</h5>
                                </div>
                                <button className="ar-button style-flat" type="submit">
                                    <span className="button-text">Start free trial</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CalltoActionTwo;