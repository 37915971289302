import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class BoxIconTwo extends Component{
    render(){
        let Data =[
            {
                iconinner: 'icon-inner',
                icon: 'fab fa-react',
                title: 'React Native'
            },
            {
                iconinner: 'icon-inner window',
                icon: 'fab fa-microsoft',
                title: 'Window'
            },
            {
                iconinner: 'icon-inner apple',
                icon: 'fab fa-apple',
                title: 'MacOS'
            },
            {
                iconinner: 'icon-inner java',
                icon: 'fab fa-java',
                title: 'Java'
            },
            {
                iconinner: 'icon-inner android',
                icon: 'fab fa-android',
                title: 'Android'
            },
            {
                iconinner: 'icon-inner php',
                icon: 'fab fa-php',
                title: 'Php'
            },

        ]
        let DataList = Data.map((val , i) => {
            return (
                <Col lg="2" md="4" sm="4" xs="6" key={i}>
                    <div className="box-icon--2">
                        <div className="content">
                            <div className={`${val.iconinner}`}>
                                <i className={`${val.icon}`}/>
                            </div>
                            <div className="inner">
                                <h5>{val.title}</h5>
                            </div>
                        </div>
                    </div>
                </Col>
            )
        })
        return(
            <Row className="row--0">
                {DataList}
            </Row>
        )
    }
}
export default BoxIconTwo;
