
export const blogReducers = (state = {}, action) => {
    return [{
            id: '1',
            thumbnail: '01',
            title: 'Specialized Design Tools for 2019',
            category: 'APP RELEASE',
            blogMeta: {
                authorThumb: '01',
                authorName: 'Harry Ferguson',
                PostDate: 'January 14, 2019',
            },
        },
        {
            id: '2',
            thumbnail: '02',
            title: 'Foundation 3 – the Truly Great Move',
            category: 'APP RELEASE',
            blogMeta: {
                authorThumb: '01',
                authorName: 'Nipa Bali',
                PostDate: 'January 14, 2019',
            },
        },
        {
            id: '3',
            thumbnail: '03',
            title: 'Creativity Boosting App is Here',
            category: 'APP RELEASE',
            blogMeta: {
                authorThumb: '01',
                authorName: 'hang Chang',
                PostDate: 'January 14, 2019',
            },
        },
        {
            id: '4',
            thumbnail: '04',
            title: 'D&AD Festival 2019’s Brand Identity',
            category: 'WEB DESIGN',
            blogMeta: {
                authorThumb: '01',
                authorName: 'Harry Ferguson',
                PostDate: 'January 14, 2019',
            },
        },
        {
            id: '5',
            thumbnail: '05',
            title: 'Designers’ Identities & Social Unconscious Bias',
            category: 'PROJECT SHOWCASE',
            blogMeta: {
                authorThumb: '01',
                authorName: 'Harry Ferguson',
                PostDate: 'January 14, 2019',
            },
        },
        {
            id: '6',
            thumbnail: '06',
            title: 'Slack’s Most Famous Rebranding Campaign',
            category: 'PROJECT SHOWCASE',
            blogMeta: {
                authorThumb: '01',
                authorName: 'Harry Ferguson',
                PostDate: 'January 20, 2019',
            },
        }
    ]
}





