import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";

class AttributeList extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Attribute List || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Attribute List'} />
                {/* End Breadcrumb Area */}


                <div className="attribute-list-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="attribute">
                                    <h3 className="mb--50">Style 01</h3>
                                    <ul className="attribute-list">
                                        <li>
                                            <h6 className="title">Size</h6>
                                            <span className="value">70mm x70mm x 25mm</span>
                                        </li>
                                        <li>
                                            <h6 className="title">WEIGHT</h6>
                                            <span className="value">196g</span>
                                        </li>
                                        <li>
                                            <h6 className="title">MATERIALS</h6>
                                            <span className="value">Aluminium / textile / Rubber / <br /> Polymer / Foam / Silicone / Nylon</span>
                                        </li>
                                        <li>
                                            <h6 className="title">FREQUEENCY</h6>
                                            <span className="value">20 - 20,000 Hz</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 mt_md--40 mt_sm--40">
                                <div className="attribute">
                                    <h3 className="mb--50">Style 02</h3>
                                    <ul className="attribute-list--2">
                                        <li>
                                            <div className="name">
                                                <h6 className="title">Size</h6>
                                            </div>
                                            <div className="value">
                                                <span className="value">70mm x70mm x 25mm</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="name">
                                                <h6 className="title">Weight</h6>
                                            </div>
                                            <div className="value">
                                                <span className="value">196g</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="name">
                                                <h6 className="title">Materials</h6>
                                            </div>
                                            <div className="value">
                                                <span className="value">Aluminium / textile / Rubber / <br/> Polymer / Foam / Silicone / Nylon</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="name">
                                                <h6 className="title">Frequeency</h6>
                                            </div>
                                            <div className="value">
                                                <span className="value">20 - 20,000 Hz</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

            </div>
            
        )
    }
}
export default AttributeList;