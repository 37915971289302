import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";


class Button extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Button || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Button'} />
                {/* End Breadcrumb Area */}
                
                {/* Start Button Wrapper */}
                <div className="button-wrapepr ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="mb--35">Button Size</h4>
                                <div className="button-group">
                                    <button className="ar-button style-flat xs-small" type="submit">
                                        <span className="button-text">Extra Small</span>
                                    </button>
                                    <button className="ar-button style-flat sm-size" type="submit">
                                        <span className="button-text">Small Size</span>
                                    </button>
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">Standard Size</span>
                                    </button>
                                    <button className="ar-button style-flat large-size" type="submit">
                                        <span className="button-text">Large Size</span>
                                    </button>
                                </div>
                                
                                <div className="button-group mt--20">
                                    <button className="ar-button style-flat custom-size" type="submit">
                                        <span className="button-text">Custom Size</span>
                                    </button>
                                </div>

                                <div className="single-button mt--20">
                                    <button className="ar-button style-flat full-width" type="submit">
                                        <span className="button-text">Custom Size</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                {/* End Button Wrapper */}
                
                {/* Start Button Wrapper */}
                <div className="button-wrapepr pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="mb--35">Button Style</h4>
                                <div className="button-group">
                                    <button className="ar-button style-flat" type="submit">
                                        <span className="button-text">Standard Size</span>
                                    </button>
                                    <button className="ar-button style-flat solid-style" type="submit">
                                        <span className="button-text">Solid Style</span>
                                    </button>
                                    <button className="ar-button style-flat rectangle" type="submit">
                                        <span className="button-text">Rectangle</span>
                                    </button>
                                    <button className="ar-button style-flat buttet" type="submit">
                                        <span className="button-text">Bullet</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                {/* End Button Wrapper */}
                
                {/* Start Button Wrapper */}
                <div className="button-wrapepr pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="mb--35">Unlimited Skin</h4>
                                <div className="button-group">
                                    <button className="ar-button style-flat primary" type="submit">
                                        <span className="button-text">Primary Button</span>
                                    </button>
                                    
                                    <button className="ar-button style-flat secondary" type="submit">
                                        <span className="button-text">Secondary Button</span>
                                    </button>

                                    <button className="ar-button style-flat custom" type="submit">
                                        <span className="button-text">Custom Color</span>
                                    </button>

                                    <button className="ar-button style-flat white" type="submit">
                                        <span className="button-text">White Button</span>
                                    </button>
                                    

                                    <button className="ar-button style-flat gradient" type="submit">
                                        <span className="button-text">Gradient Button</span>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                {/* End Button Wrapper */}
                
                {/* Start Button Wrapper */}
                <div className="button-wrapepr pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="mb--35">With Ton Of Icons</h4>
                                <div className="button-group">
                                    <button className="ar-button style-flat with-icon" type="submit">
                                        <span className="button-text">Standard Style <i className="fa fa-bolt"></i></span>
                                    </button>
                                    <button className="ar-button style-flat with-icon" type="submit">
                                        <span className="button-text">Standard Style <i className="fa fa-arrow-right"></i></span>
                                    </button>
                                    <button className="ar-button style-flat with-icon" type="submit">
                                        <span className="button-text">Standard Style <i className="fa fa-binoculars"></i></span>
                                    </button>
                                    <button className="ar-button style-flat with-left" type="submit">
                                        <span className="button-text"><i className="fa fa-bolt"></i> Standard Style</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                {/* End Button Wrapper */}
                
                
                {/* Start Button Wrapper */}
                <div className="button-wrapepr pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4 className="mb--35">With Image</h4>
                                <div className="button-group">
                                    <button className="button-image" type="submit">
                                        <img src={require('../assets/images/logo/app-store-dark.jpg').default} alt="Logo Images"/>
                                    </button>

                                    <button className="button-image" type="submit">
                                        <img src={require("../assets/images/logo/google-play-dark.jpg").default} alt="Logo Images"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
                {/* End Button Wrapper */}


            </div>
        )
    }
}


export default Button;

