import React, { Component } from "react";
import Subscribe from "../../common/Subscribe";

class Newsletter extends Component{
    render(){
        return(
            <div className="newsletter-area branding-newsletter ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--55 mb_sm--20 mb_md--20">
                                <h3 className="heading text-white fontWeight600">493 businesses signed up <br /> last week. Join them!</h3>
                            </div>
                            <div className="inner">
                                <div className="newsletter-form--1">
                                    <Subscribe />
                                </div>
                                <h6 className="next-arrowland title text-center text-white">What’s next in AeroLand?  <a className="link-hover" href="/branding">Learn more</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Newsletter;
