import React, { Component } from "react";


class ListOne extends Component{
    render(){
        var names = [
            'Receive real-time business analytics',
            'Cross-browser Compatible Design',
            'Completely Gutenberg Ready',
            'Highly Responsive Tools',
        ];
        return(
            <ul className="list-style--1">
                {names.map((name, index) => {
                    return <li key={ index }><i className="fa fa-check"></i> {name}</li>;
                })}
            </ul>
        )
    }
}
export default ListOne;