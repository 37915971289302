import React, { Component , Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class BlogClassicOne extends Component{
    render(){
        const { posts }  = this.props;
        const PostList = posts.slice(0 , 6);
        return(
            <Fragment>
                <div className="row">
                    {PostList.map( post => (
                        <div className={this.props.SingleColumn} key={post.id} >
                            <div className="blog">
                                <div className="thumbnail">
                                    <Link to={'/blog-classic/' + post.id}>
                                        <img className="w-100" src={require(`../../assets/images/blog/blog-${post.thumbnail}.jpg`).default} alt="Blog Images"/>
                                    </Link>
                                </div>
                                <div className="content">
                                    <span className="category">{post.category}</span>
                                    <h4 className="title">
                                        <Link to={'/blog-classic/' + post.id}>
                                            {post.title}
                                        </Link>
                                    </h4>
                                    <div className="post-meta">
                                        <div className="meta-info">
                                            <img src={require(`../../assets/images/blog/clint-${post.blogMeta.authorThumb}.png`).default} alt="Author"/>
                                            <h6 className="author">{post.blogMeta.authorName}</h6>
                                        </div>
                                        <div className="date">
                                            <span>{post.blogMeta.PostDate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        posts: state.blogReducers
    }
}
export default connect(mapStateToProps)(BlogClassicOne);