import React, { Component } from "react";


class Brand2 extends Component{

    render(){
        let Data = [
            {
                client : '01',
                client2: '01'
            },

            {
                client : '02',
                client2: '02'
            },

            {
                client : '03',
                client2: '03'
            },

            {
                client : '04',
                client2: '04'
            },

            {
                client : '05',
                client2: '05'
            },

            {
                client : '06',
                client2: '06'
            },

            {
                client : '07',
                client2: '07'
            },

            {
                client : '08',
                client2: '08'
            },
            {
                client : '09',
                client2: '09'
            },

            {
                client : '10',
                client2: '10'
            },
            {
                client : '11',
                client2: '11'
            },

            {
                client : '12',
                client2: '12'
            }

        ];
        let DataList = Data.map((val, i) =>{
            return(
                <div className="brand" key={i}>
                    <a href="/">
                        <div className="thumb2">
                            <img src={require(`../../assets/images/brand/client2-${val.client2}.png`).default} alt="Client Images"/>
                        </div>
                        <div className="thumb">
                            <img src={require(`../../assets/images/brand/client-${val.client}.png`).default} alt="Client Images"/>
                        </div>
                    </a>
                </div>
            )
        });

        return(
            <div className="col-lg-12">
                <div className="brand-grid-list">
                    {DataList}
                </div>
            </div>
        )
    }
}
export default Brand2;
