import React, { Component } from "react";
import {Helmet} from 'react-helmet';
import Breadcrumb from "../components/common/Breadcrumb";
import TeamOne from "./component/team/TeamOne";
import TeamTwo from "./component/team/TeamTwo";

class Team extends Component{
    render(){
        return(
            <div>
                <Helmet>
                    <title>Team || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>
                
                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'Team member'} />
                {/* End Breadcrumb Area */}

                {/* Start Team Area */}
                <div className="team-area ptb--120 bg-color" data-bg-color="#ffffff">
                    <TeamOne />
                </div>
                {/* End Team Area */}

                {/* Start Team Area */}
                <div className="team-area ptb--120 theme-gradient">
                    <TeamTwo />
                </div>
                {/* End Team Area */}

            </div>
        )
    }
}
export default Team;
