import React, { Component ,Fragment } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import Portfolio from "./components/Portfolio";

class PortfolioGridCaption extends Component{
    render(){
        return(
            <Fragment>

                <Helmet>
                    <title>PORTFOLIO GRID CAPTION || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
                <Breadcrumb title={'PORTFOLIO GRID CAPTION'} />
                {/* End Breadcrumb Area */}
                
                {/* Start portfolio Metro Area */}
                <div className="portfolio-metro-area ptb--120 bg_color--5">
                    <div className="container">
                        <Portfolio SingleColumn="col-lg-4 col-md-6 col-sm-6 col-12" PortfolioStyle="portfolio-with-caption" startItem="0" col="6" categoryShow="yes"  />
                    </div>
                </div>
                {/* End portfolio Metro Area */}


            </Fragment>
        )
    }
}
export default PortfolioGridCaption;