import React, { Component , Fragment } from "react";

class NotFound extends Component{
    render(){
        return(
            <Fragment>
                <div className="page-not-found">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content">
                                    <div className="inner text-center">
                                        <div className="thumbnail mb--50">
                                            <img src={require("../assets/images/bg/page-404-image.png").default} alt="App Landing"/>
                                        </div>
                                        <h2 className="title">Oops! Page not found!</h2>
                                        <a href="/" className="ar-button style-flat with-icon mt--40 mt_sm--20">
                                            <span className="button-text">Go back to homepage <i className="fa fa-home"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default NotFound;