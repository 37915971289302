import React from "react";






export const SectionTitleDefault = (props) => {
    return(
        <div className={`section-title ${props.textAlign}`}>
            <h2 className="title fontWeight400">{props.title}</h2>
        </div>
    )
}



export const SectionTitle = (props) => {
    return(
        <div className={`section-title--5 ${props.textAlign}`}>
            <span>{props.subtitle}</span>
            <h2 className="title fontWeight600">{props.title}</h2>
        </div>
    )
}


export const SectionTitle2 = (props) => {
    return(
        <div className={`section-title--4 ${props.textAlign}`}>
            <span>{props.subtitle}</span>
            <h3 className="title mb--25">{props.title}</h3>
        </div>
    )
}









