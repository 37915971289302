import React, { Component } from "react";
import {Helmet} from 'react-helmet'
import Breadcrumb from "../components/common/Breadcrumb";
import PricingBoxOne from "./component/pricingBox/PricingBoxOne";
import PricingBoxThree from "./component/pricingBox/PricingBoxThree";
import PricingBoxFour from "./component/pricingBox/PricingBoxFour";
import {SectionTitleDefault} from "../components/sectiontitle/SectionTitle";
import {PricingBoxFiveData} from "../home/data";

class PricingBox extends Component{
    render(){
        let PricingBoxSlice = PricingBoxFiveData.slice(0, 3);
        return(
            <div>
                <Helmet>
                    <title>Pricing Box || Aeroland React Multipurpose React Template</title>
                    <meta name="description" content="Aeroland – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Aeroland Bootstrap 4 Template will create that various landing Template." />
                </Helmet>

                {/* Start Breadcrumb Area */}
               <Breadcrumb title={'Pricing Box'} />
                {/* End Breadcrumb Area */}

                {/* Start Pricing Box */}
                <div className="pricing-box-area ptb--120">
                    <div className="container">
                        <div className="row mb--20 mb_sm--0">
                            <div className="col-lg-12">
                                <SectionTitleDefault textAlign="text-center" title="Affordable for everyone!" />
                            </div>
                        </div>
                        <PricingBoxOne />
                    </div>
                </div>
                {/* End Pricing Box */}

                {/* Start Pricing Box */}
                <div className="pricing-box-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row mb--20 mb_sm--0">
                            <div className="col-lg-12">
                                <SectionTitleDefault textAlign="text-center" title="Affordable for everyone!" />
                            </div>
                        </div>
                        <PricingBoxThree />
                    </div>
                </div>
                {/* End Pricing Box */}

                {/* Start Pricing Box */}
                <div className="pricing-box-area ptb--120 bg_color--6">
                    <div className="pricong-box-wrapper pl--130 pl_sm--15 pr--130 pr_sm--15">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 col-xl-3">
                                <div className="section-title mb--20 mb_sm--0">
                                    <h2 className="fontWeight400 text-white mb--35">Affordable pricing plans!</h2>
                                    <ul className="list-style--1 text-white">
                                        <li><i className="fa fa-check"></i> Fast, reliable and secure</li>
                                        <li><i className="fa fa-check"></i> Extendable support period</li>
                                        <li><i className="fa fa-check"></i> Regular design updates</li>
                                    </ul>
                                    <h6 className="text-white mt--40 mb--0 font--15">What’s next in AeroLand? <a className="color-4 link-hover" href="/">Learn more</a> </h6>
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-9 col-12">
                                <PricingBoxFour />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Pricing Box */}

                {/* Start Pricing Box */}
                <div className="pricing-box-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitleDefault textAlign="text-center" title="Affordable for everyone!" />
                            </div>
                        </div>
                        <div className="row">
                            {PricingBoxSlice.map( (value , index) =>(
                                <div className="col-lg-4 col-md-6 col-12 mt--30" key={index}>
                                    <div className="pricing-box--5">
                                        <div className="inner">
                                            <div className="thumbnail">
                                                <img src={`/assets/images/aeroland/pricing-05-image-${value.image}.png`} alt=""/>
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{value.title}</h4>
                                                <div className="feature-list">
                                                    <ul className="pricing-feature-list">
                                                        {value.PricingBoxFiveFeature}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="pricing-footer">
                                                <button>See Pricing</button>
                                            </div>
                                        </div> 
                                    </div> 

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Pricing Box */}

            </div>
        )
    }
}
export default PricingBox;